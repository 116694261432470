.textArea {
  font-family: sans-serif;
  width: 100%;
  min-height: 20%;
  border: 2px solid rgb(170, 170, 170);
  padding: 2%;
  z-index: 5;
  word-wrap: break-word;
}

.campGuidanceText {
  line-height: 4;
}
.dialogTextArea {
  font-family: monospace, monospace;
  height: 88%;
  margin-left: 1%;
  margin-right: 1%;
  font-size: 17px;
}

h3 {
  color: #037082;
  font-weight: 500;
}

.button {
  display: block;
  margin-top: 3%;
  outline: none;
  background: #eee;
  border-color: rgb(170, 170, 170);
  padding: 5px;
  color: #037082;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.popupInner {
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  height: 90vh;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.popupHeader h3 {
  margin: 0;
  font-size: 1.25rem;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

.closeButton:hover {
  color: #333;
}

.popupContent {
  flex-grow: 1;
  padding: 40px;
  overflow-y: auto;
  max-height: calc(90vh - 150px);
}

.popupFooter {
  padding: 15px 20px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.saveButton:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .popupInner {
    width: 90%;
    max-height: 80vh;
  }
}
