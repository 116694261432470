.leftSection {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  overflow-x: hidden;
  border-right: 1px solid rgb(155, 155, 155);
}

.rightSection {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homePage {
  height: 100%;
}

.buttonSection {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding-bottom: 10px;
}
.flex {
  display: flex;
}

.container {
  display: flex;
  height: calc(100vh - 65px);
  position: relative;
}

.sidebarParagraph {
  padding-left: 20px;
  color: #037082;
  font-weight: 500;
  font-size: 20px;
  line-height: 3;
}

.goToBtn {
  order: 3;
}
.unTakeBtn {
  order: 2;
}
.reportABugBtn {
  order: 1;
}
.btn {
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background: #f1efef;
  border: 1px solid grey;
}

.labeler {
  padding: 1%;
  display: flex;
  justify-content: space-around;
}

.campaignsTable {
  margin-left: 4%;
}

.marginLeft {
  margin-left: 30%;
}

.marginTop {
  position: absolute;
  top: 670px;
  margin-left: 66px;
}

.deletedTable {
  margin-right: 20px;
}

.paragraph {
  color: #037082;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}

.pullTask {
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 3%;
}
.hidden {
  display: none;
}

.hourglass {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 1;
}

.center {
  position: fixed;
  top: 230px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nextTask {
  width: 145px;
}

.specificTaskText {
  width: 100px;
  margin: 10px;
}

.tableContainer {
  margin-top: 30px;
}

.version {
  color: #037082;
  font-weight: 500;

  bottom: 0;
  position: absolute;
  width: 100%;
  background: #f2f7fa;
  text-align: center;
  line-height: 2;
  margin-top: 10px;
  position: sticky;
}

.inlineContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 2%;
}
